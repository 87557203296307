
import { Fragment } from 'react';
import { ControllerProps, Controller, FieldError } from 'react-hook-form';

import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import FormGroup, { FormGroupProps } from '@mui/material/FormGroup';
import Grid, { GridProps } from '@mui/material/Grid';
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup';
import RadioButtonChecked from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUnchecked from '@mui/icons-material/RadioButtonUnchecked';

interface Props {
    label?: string;
    name: string;
    control: ControllerProps["control"];
    groupList: { value: string | boolean | number, label: string }[];
    radioGroupProps?: RadioGroupProps;
    defaultValue?: string | boolean | number;
    rules?: ControllerProps["rules"];
    error?: FieldError;
    labelPlacement?: "top" | "left",
    disabled?: boolean,

    formGroupProps?: FormGroupProps;
    gridProps?: GridProps;
}

interface sProps {
    label?: string;
    name: string;
    groupList: { value: string | boolean | number, label: string }[];
    value?: string;
    labelProps?: FormControlLabelProps & any;
}

const CustomRadioGroup = ({ label, name, control, groupList, defaultValue, formGroupProps, gridProps, disabled = false, labelPlacement = "top" }: Props) => {
    let labelStyle = labelPlacement === "top" ? { marginBottom: "5px" } : { marginRight: "10px" };

    return (
        <FormControl disabled={disabled} component="fieldset" style={labelPlacement === "top" ? {} : { display: "flex", flexDirection: "row", alignItems: "center" }}>
            {label && <span className="text-13 font-medium" style={labelStyle}>{label}: </span>}

            <FormGroup
                row
                style={{ marginLeft: "5px" }}
                {...formGroupProps}
            >
                <Controller
                    name={name}
                    defaultValue={defaultValue}
                    render={({ field: { onChange, value } }) => {
                        return <>
                            {groupList.map((item) => {
                                return (
                                    <GridContainer key={item.value} gridProps={gridProps}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color='primary'
                                                    checked={item.value === value}
                                                    size="small"
                                                    style={{ padding: "5px" }}
                                                    value={item.value}
                                                    onChange={e => {
                                                        let val = e.target.value;
                                                        onChange(val === 'true' ? true : val === 'false' ? false : typeof (item.value) === "number" ? +val : val)
                                                    }}
                                                    icon={<RadioButtonUnchecked />}
                                                    checkedIcon={<RadioButtonChecked />}
                                                />
                                            }
                                            label={item.label}
                                            classes={{ label: "text-12 font-regular" }}
                                        />
                                    </GridContainer>
                                )
                            })}
                        </>
                    }}
                    control={control}
                />

            </FormGroup>
        </FormControl>
    )
}

const RadioGroupComponent = ({ label, groupList, name, value, labelProps, ...rest }: sProps & RadioGroupProps) => {
    return (
        <FormControl component="fieldset">
            {label && <span className="text-13 font-medium" style={{ marginBottom: "6px" }}>{label}</span>}
            <RadioGroup
                row
                name={name}
                value={value || ""}
                style={{ marginLeft: "5px" }}
                {...rest}
            >
                {groupList.map((item, index) => (
                    <FormControlLabel
                        key={index}
                        value={item.value}
                        control={<Radio color='primary' size="small" style={{ padding: "5px" }} />}
                        classes={{ label: "text-12 font-regular" }}
                        label={item.label}
                        {...labelProps}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    )
}


export { CustomRadioGroup, RadioGroupComponent };

const GridContainer = ({ gridProps, children }: any) => {
    if (gridProps) {
        return <Grid {...gridProps}>
            {children}
        </Grid>
    } else {
        return <Fragment>
            {children}
        </Fragment>
    }
}