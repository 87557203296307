import { defaultObjectState, masterLookupServices } from "utils/constants";
import { DefaultReducerObjectType, LookupActionType } from "utils/types";
import {
    GET_MASTER_LOOKUP_DATA_START,
    GET_MASTER_LOOKUP_DATA_SUCCESS,
    GET_MASTER_LOOKUP_DATA_FAIL,
} from "../actionTypes";

const initialState: { [key: string]: DefaultReducerObjectType } = Object.values(masterLookupServices).reduce((acc: any, curr:any) => (acc[curr] = defaultObjectState, acc), {}) as any;

export const masterLookupReducer = (state = initialState, action: LookupActionType): { [key: string]: DefaultReducerObjectType } => {
    switch (action.type) {
        case GET_MASTER_LOOKUP_DATA_START:
            return {
                ...state,
                [action.serviceType]: {
                    ...state[action.serviceType],
                    loading: true,
                    error: false
                }
            }
        case GET_MASTER_LOOKUP_DATA_SUCCESS:
            return {
                ...state,
                [action.serviceType]: {
                    ...state[action.serviceType],
                    loading: false,
                    data: action.payload
                }
            }
        case GET_MASTER_LOOKUP_DATA_FAIL:
            return {
                ...state,
                [action.serviceType]: {
                    ...state[action.serviceType],
                    loading: false,
                    error: true
                }
            }
        default:
            return state;
    }
};