import { combineReducers } from 'redux';
import { RootReducerState } from 'utils/types/rootReducer.type';

import {
  intlReducer,
  authReducer,
  masterPaginationReducer,
  masterLookupReducer,
  utilityReducer,
} from './reducers';

export const rootReducers = combineReducers<RootReducerState|any>({
  intl: intlReducer,
  auth: authReducer,
  masterPaginationReducer,
  masterLookupReducer,
  utilityReducer,
});