import axios from 'axios';

export default function networkInterceptor(store:any) {
    axios.interceptors.request.use(
        (config:any) => {
            const {
                auth: { data: { token } },
            } = store.getState();

            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }

            return config;
        },
       (err:any) => Promise.reject(err)
    );
}

