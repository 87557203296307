
const CryptoJS = require("crypto-js");
const key = CryptoJS.enc.Utf8.parse("artfertility2021"); //MUST BE 16 CHAR
const iv1 = CryptoJS.enc.Utf8.parse("artfertility2021"); //MUST BE 16 CHAR

const JsonFormatter = {
    stringify: (cipherParams: any) => {
        const jsonObj: any = cipherParams.ciphertext.toString(CryptoJS.enc.Hex);

        return jsonObj;
    },
    parse: (jsonStr: any) => {
        // extract ciphertext from json object, and create cipher params object
        const cipherParams = CryptoJS.lib.CipherParams.create({
            ciphertext: CryptoJS.enc.Hex.parse(jsonStr)
        });

        return cipherParams;
    }
};

const options = {
    iv: iv1,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
    format: JsonFormatter
}

export function AESEncrypt(plainText: string): string {
    const encrypted = CryptoJS.AES.encrypt(plainText, key, options);
    return encrypted.toString();
}

export function AESDecrypt(cipher: string): string {
    const plainText = CryptoJS.AES.decrypt(cipher, key, options);

    return plainText.toString(CryptoJS.enc.Utf8);
}
