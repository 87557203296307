import * as React from 'react'

export const lazy = <T extends React.ComponentType<any>>(factory: () => Promise<{ default: T }>, minLoadTimeMs = 800) => React.lazy(() => Promise.all([factory(), new Promise((resolve) => setTimeout(resolve, minLoadTimeMs))]).then(([moduleExports]) => moduleExports));

export const lazyLoad = <T extends {}, U extends keyof T>(
    loader: (x?: string) => Promise<T>
) =>
    new Proxy(({} as unknown) as T, {
        get: (target: any, componentName: string | symbol) => {
            if (typeof componentName === 'string') {
                return lazy(() =>
                    loader(componentName).then((x) => ({
                        default: (x[componentName as U] as any) as React.ComponentType<any>,
                    }))
                )
            }
        },
    });
