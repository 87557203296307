import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';

const HoverLoader = () => {
    return (
        <Modal
            open={true}
        >
            <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', height: '100%' }} >
                <CircularProgress color="primary" />
            </div>
        </Modal>
    )
}

export { HoverLoader };