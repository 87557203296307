import { forwardRef, Fragment } from "react";

import { Card, styled, Typography } from "@mui/material";

interface Props {
  children:
    | React.ReactNode
    | React.ReactElement
    | React.ReactNode[]
    | React.ReactElement[];
  title?: any;
}

const Header = styled(Typography)(({ theme }: any) => ({
  padding: theme.spacing(2, 2),
  background: theme.palette.primary.lighter,
}));

const Body = styled(Card)(({ theme }: any) => ({
  padding: theme.spacing(2, 2),
  marginBottom: 20,
  borderRadius:0
}));

const SectionLayout = forwardRef(({ children, title }: Props, ref: any) => {
  return (
    <Fragment>
      <Header ref={ref} variant="h6">
        {title}{" "}
      </Header>
      <Body>{children}</Body>
    </Fragment>
  );
});

export default SectionLayout;
