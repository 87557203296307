import React from 'react';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

export const SlideTransition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    const { children, ...rest } = props;
    return (
        <Slide direction="up" ref={ref} {...rest} mountOnEnter unmountOnExit timeout={400} >
            {children}
        </Slide>
    );
});